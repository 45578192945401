import toast from "react-hot-toast";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const SignIn = ({
  email,
  setEmail,
  password,
  setPassword,
  setCurrentStep,
  goBackward,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (email.length > 3 && password.length > 3) {
          setCurrentStep(8);
        } else {
          toast.error("Please enter your email and password");
        }
      }}
    >
      <p className="pt-0 mt-0">
        Almost done! Freebie Flow now requires your Amazon account email and
        password so that it can check out items on your behalf. All account
        information is encrypted and securely stored in the cloud.
      </p>
      <div className="flex flex-col gap-2.5 sm:flex-row">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
          placeholder="Email..."
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className=" block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
          placeholder="Password..."
          required
        />
      </div>
      <div className="mt-5 flex w-full justify-between gap-2.5">
        <button
          type="button"
          onClick={goBackward}
          className="flex h-[36px] w-[36px] shrink-0 items-center justify-center gap-1.5 rounded-full bg-gray-100 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
        >
          <FaChevronLeft />
        </button>
        <button
          type="submit"
          className="flex shrink-0 items-center justify-center gap-1.5 rounded-full bg-gray-100 py-2 px-5 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
        >
          Log in <FaChevronRight className="text-xs" />
        </button>
      </div>
    </form>
  );
};

export default SignIn;
