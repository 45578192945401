import React, { useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { createSubscription, displayPrice } from "../../utils/checkout";
import { defaultPriceId, defaultPrice } from "../../config";

export default function CheckoutForm({
    setClientSecret,
    setMessage,
    message,
    isLoading,
    setIsLoading,
    isApplyingCoupon,
    setIsApplyingCoupon,
    showCoupon,
    setShowCoupon,
    couponMessage,
    setCouponMessage,
    couponApplied,
    setCouponApplied,
    price,
    setPrice,
    coupon,
    setCoupon,
}) {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
        setIsLoading(true);

        e.preventDefault();
        if (!stripe || !elements) return;

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/payment/success`,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const removeCoupon = async () => {
        setPrice(defaultPrice);
        console.log("Removing coupon");
        setCouponMessage("");
        setCouponApplied(false);
        setCoupon(null);
        setShowCoupon(false);
        const subscription = await createSubscription(defaultPriceId, null);
        setClientSecret(subscription.clientSecret);
    };

    const applyCoupon = async () => {
        setIsApplyingCoupon(true);
        const subscription = await createSubscription(defaultPriceId, coupon);
        if (subscription.paymentFinished) {
            window.location.href = "/payment/success";
            return;
        }
        if (subscription.newPrice) {
            setPrice(subscription.newPrice);
        }
        if (subscription.error) {
            setCouponMessage(subscription.message);
        } else {
            setCouponMessage("Coupon applied!");
            setCouponApplied(true);
            setShowCoupon(false);
            setClientSecret(subscription.clientSecret);
        }
        setIsApplyingCoupon(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
                id="payment-element"
                options={paymentElementOptions}
                onReady={() => setIsLoading(false)}
            />
            <div className="mt-4 flex flex-col gap-1">
                <button
                    disabled={isLoading || !stripe || !elements}
                    id="submit"
                >
                    <span id="button-text">
                        {isLoading ? (
                            <div className="spinner" id="spinner"></div>
                        ) : (
                            `Pay ${displayPrice(price)}`
                        )}
                    </span>
                </button>
                {!isLoading && (
                    <button
                        className="rounded-md text-sm text-blue-700"
                        onClick={async (e) => {
                            e.preventDefault();
                            setShowCoupon(!showCoupon);
                            setCouponMessage("");
                            if (couponApplied) await removeCoupon();
                        }}
                    >
                        {showCoupon
                            ? "Remove"
                            : couponApplied
                            ? "Remove"
                            : "Use"}{" "}
                        coupon
                    </button>
                )}
                {couponMessage && (
                    <div
                        className={
                            "text-sm " +
                            (couponMessage === "Invalid coupon"
                                ? "text-red-600"
                                : "text-green-700")
                        }
                    >
                        {couponMessage}
                    </div>
                )}
                {showCoupon && (
                    <div className="mt-4 flex flex-col text-left">
                        <label
                            htmlFor="coupon"
                            className="text-[gb(48, 49, 61)]"
                        >
                            Coupon
                        </label>
                        <input
                            id="coupon"
                            placeholder="COUPON10"
                            onChange={(e) => setCoupon(e.target.value)}
                            className="b-1 text-[rgb(48, 49, 61)] rounded-md border bg-[#fff] p-[0.75rem]"
                        />
                        <button
                            className={
                                "button-gradient mt-4 flex " +
                                (isApplyingCoupon
                                    ? "items-center justify-center"
                                    : "")
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                applyCoupon();
                            }}
                        >
                            {isApplyingCoupon ? (
                                <div
                                    role="status"
                                    className="flex items-center justify-center"
                                >
                                    <svg
                                        aria-hidden="true"
                                        class="h-5 w-5 animate-spin fill-white text-gray-200 dark:text-gray-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                </div>
                            ) : (
                                <span>Apply coupon</span>
                            )}
                        </button>
                    </div>
                )}
            </div>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}
