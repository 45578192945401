import Card from "./Card";
import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import ManualSetup from "./ManualSetup";
import SignIn from "./SignIn";

export default function Guide() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [manualSetup, setManualSetup] = useState(false);

  const goForward = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const goBackward = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  useEffect(() => {
    console.log(manualSetup);
  }, [manualSetup]);

  const steps = [
    {
      title: "Let's get started",
      description:
        "This process takes about 10 minutes, but if you follow the instructions correctly you’ll be receiving boxes very soon!",
      first: true,
    },
    {
      title: "Ensure 2-factor authentication is disabled",
      list: [
        "Go to this link: https://www.amazon.com/a/settings/approval",
        "Click the disable button",
        "Check the checkbox",
        "Click disable",
      ],
    },
    {
      title: "Set up 2FA",
      list: [
        'Click the yellow "Get Started" button',
        "Select the Authenticator app option",
        "Click 'Can't Scan The Barcode?' (only on desktop)",
        "Copy your 2FA Secret Key",
        "Paste your 2FA Secret Key in a safe place (ex: a notes app)",
      ],
    },
    {
      title: "Download Google Authenticator",
      description: (
        <>
          {/* Mobile */}
          <ol className="lg:hidden">
            <li>Get the “Google Authenticator” app on your phone</li>
            <li>Click the “+” icon to add a code</li>
            <li>Select “enter a setup key”</li>
            <li>In the account box type “Amazon”</li>
            <li>In the key box paste 2FA Secret Key</li>
            <li>Press add code</li>
          </ol>
          {/* Desktop */}
          <ol className="hidden lg:block">
            <li>Get the “Google Authenticator” app on your phone</li>
            <li>Click the “+” icon to add a code</li>
            <li>Select “Scan a QR code”</li>
            <li>Scan the QR on your Amazon page</li>
          </ol>
        </>
      ),
    },
    {
      title: "Complete 2-step setup",
      list: [
        "You will now see a 6 digit code generated in your authenticator app that changes every 30 seconds.",
        "Enter 6 digit code into the box on Amazon",
        "Select “Verify OTP and continue”",
        "Click checkbox “don’t require OTP”",
        "Select “Got it. Turn on Two-Step Verification”",
      ],
    },
    {
      title: "Sign in to Amazon",
      video: "",
      description: (
        <SignIn
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          setCurrentStep={setCurrentStep}
          goBackward={goBackward}
        />
      ),
      last: "true",
    },
  ];

  return (
    <main className="gradient flex min-h-[calc(100vh-88px)] flex-col items-center  gap-2.5 p-5 pb-32">
      <button className="leading-0 absolute top-1 right-1 flex items-center gap-1.5 rounded-md bg-black/10 px-4 py-2 text-sm font-medium text-white transition hover:bg-black/20">
        <FaTimes /> Exit setup
      </button>

      {manualSetup === false ? (
        <Card
          key={currentStep}
          index={currentStep}
          title={steps[currentStep].title}
          video={steps[currentStep].video}
          description={steps[currentStep].description}
          list={steps[currentStep].list}
          manual={steps[currentStep].manual}
          goBackward={goBackward}
          goForward={goForward}
          currentStep={currentStep}
          first={steps[currentStep].first}
          last={steps[currentStep].last}
          setManualSetup={setManualSetup}
        />
      ) : (
        <ManualSetup setManualSetup={setManualSetup} />
      )}
    </main>
  );
}
