import CTA from "../sections/CTA";
import FAQ from "../sections/FAQ";
import Hero from "../sections/Hero";
import Plans from "../sections/Plans";
import Footer from "../sections/Footer";
import { FaTimes } from "react-icons/fa";
import Features from "../sections/Features";
import HowItWorks from "../sections/HowItWorks";
import LatestDeals from "../sections/LatestDeals";
import HowItWorks1 from "../sections/HowItWorks1";
import Testimonials from "../sections/Testimonials";
import SuccessProofs from "../sections/SuccessProofs";
import { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

const Home = () => {
    const [showPopup, setShowPopup] = useState(false);

    const getGeoLocation = async () => {
        const res = await fetch("https://freeipapi.com/api/json");
        return (await res.json()).countryCode === "US";
    };

    useEffect(() => {
        getGeoLocation().then((isUS) => {
            if (!isUS) setShowPopup(true);
        });
    }, []);

    return (
        <>
            <Transition.Root show={Boolean(showPopup)} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setShowPopup(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0">
                        <div className="flex min-h-full items-end items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative w-full transform rounded-md bg-white p-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                                    <button
                                        className="absolute top-[23px] right-5 opacity-25"
                                        onClick={() => setShowPopup(false)}
                                    >
                                        <FaTimes />
                                    </button>
                                    <div className="mb-5 flex w-full items-center justify-center pl-5 pr-5">
                                        <h3 className="text-center leading-none">
                                            Your location seems to be outside of
                                            the US!
                                        </h3>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-center text-sm">
                                            Freebie Flow is currently only
                                            available in the United States.
                                        </p>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-center text-sm">
                                            Would you like to continue?
                                        </p>
                                    </div>
                                    <div className="mt-5 sm:mt-6">
                                        <button
                                            type="button"
                                            className="button-gradient button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-button-text transition-all lg:text-sm"
                                            onClick={() => setShowPopup(false)}
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Hero />
            <HowItWorks />
            <SuccessProofs />
            <LatestDeals />
            <HowItWorks1 />
            <Features />
            <Testimonials />
            <Plans />
            <FAQ />
            <CTA />
            <Footer />
        </>
    );
};

export default Home;
