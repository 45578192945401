import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./landing/pages/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import Register from "./dashboard/pages/Register";
import SignIn from "./dashboard/pages/SignIn";
import Dashboard from "./dashboard/pages/Dashboard";
import Settings from "./dashboard/pages/Settings";
import Plans from "./dashboard/pages/Plans";
import DashNav from "./dashboard/components/Nav";
import Nav from "./landing/components/Nav";
import { useDataContext } from "./context/UserContext";
import toast, { Toaster } from "react-hot-toast";
import ResetPassword from "./dashboard/pages/ResetPassword";
import VerifyEmail from "./dashboard/pages/VerifyEmail";
import axios from "axios";
import { Bars } from "react-loading-icons";
import { useEffect, useState } from "react";
import { IntercomProvider } from "react-use-intercom";
import clsx from "clsx";
import PrivacyPolicy from "./landing/pages/PrivacyPolicy";
import EmailSignup from "./landing/pages/EmailSignup";
import ToS from "./landing/pages/TermsOfService";
import SuccessfulPayment from "./dashboard/pages/SuccessfulPayment";
import Warehouse from "./warehouse";
import Steps from "./steps/Steps";
import { FaInfoCircle } from "react-icons/fa";
import CustomPlans from "./dashboard/pages/CustomPlans";
import Modal from "./global-components/Modal";
import Guide from "./guide/Guide";
import Stripe from "./dashboard/components/Stripe";
import CheckoutForm from "./dashboard/components/CheckoutForm";

AOS.init();

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID ?? "hrjli0vq";

function App() {
    const location = useLocation();
    const { user } = useDataContext();
    const [sent, setSent] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <>
            <Modal />
            <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
                {location.pathname.includes("dashboard") && <DashNav />}
                {(location.pathname === "/" ||
                    location.pathname.startsWith("/home")) && <Nav />}
                <Routes>
                    <Route path="/">
                        <Route path="/" index={true} element={<Home />} />
                        <Route path="/home" index={true} element={<Home />} />
                    </Route>
                    <Route path="/stripe" element={<CheckoutForm />}></Route>
                    <Route path="dashboard">
                        <Route
                            path="/dashboard"
                            index={true}
                            element={
                                user ? (
                                    user.maxAccounts && user.verifiedEmail ? (
                                        <Dashboard />
                                    ) : !user.verifiedEmail ? (
                                        <div className="mx-auto max-w-screen-sm p-5">
                                            <div className="text-neutral-700 w-full rounded-lg border p-2.5">
                                                <div className="mb-0.5 flex items-center gap-1.5 font-medium">
                                                    <FaInfoCircle />
                                                    Did you know?
                                                </div>
                                                <p className="text-neutral-600 text-sm">
                                                    On average, our members
                                                    receive their first freebie{" "}
                                                    <span className="text-gradient">
                                                        within a single week
                                                    </span>{" "}
                                                    after signup.
                                                </p>
                                            </div>
                                            <div className="flex flex-col items-center gap-2.5">
                                                <Bars
                                                    fill="rgba(0,0,0,0.1)"
                                                    width="50px"
                                                />
                                                <h1 className="text-3xl font-extrabold tracking-tight">
                                                    Check your email
                                                </h1>
                                                <p className="text-center">
                                                    Click the verification link
                                                    in the email we sent to{" "}
                                                    <span className="font-semibold">
                                                        {user.username}
                                                    </span>{" "}
                                                    to continue. If you do not
                                                    see an email, make sure to
                                                    check your promotional and
                                                    spam folders.
                                                </p>
                                                <button
                                                    className={clsx(
                                                        "mt-5 rounded-md bg-gray-100 px-5 py-2 text-sm transition hover:bg-gray-200 active:bg-gray-300",
                                                        sent &&
                                                            "disabled pointer-events-none"
                                                    )}
                                                    onClick={() => {
                                                        if (sent === false)
                                                            axios
                                                                .post(
                                                                    "/resend_verification"
                                                                )
                                                                .then(() => {
                                                                    toast.success(
                                                                        "Please check your email"
                                                                    );
                                                                    setSent(
                                                                        true
                                                                    );
                                                                });
                                                    }}
                                                >
                                                    {sent === false
                                                        ? "Resend"
                                                        : "Email sent"}
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <Stripe />
                                    )
                                ) : (
                                    <Navigate to="/dashboard/signin" />
                                )
                            }
                        />
                        <Route
                            path="settings"
                            element={
                                user ? (
                                    <Settings />
                                ) : (
                                    <Navigate to="/dashboard" />
                                )
                            }
                        />
                        <Route
                            path="register"
                            element={
                                user ? (
                                    <Navigate to="/dashboard" />
                                ) : (
                                    <Register />
                                )
                            }
                        />
                        <Route
                            path="signin"
                            element={
                                user ? <Navigate to="/dashboard" /> : <SignIn />
                            }
                        />
                        <Route path="plans" element={<Stripe />} />
                        <Route path="ugccreators" element={<CustomPlans />} />
                    </Route>
                    <Route
                        path="reset_password/:token"
                        element={<ResetPassword />}
                    />
                    <Route
                        path="verify_email/:token"
                        element={<VerifyEmail />}
                    />
                    <Route path="/terms-of-service" element={<ToS />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/email-signup" element={<EmailSignup />} />
                    <Route
                        path="/dashboard/warehouse"
                        element={<Warehouse />}
                    ></Route>
                    <Route
                        path="/payment/success"
                        element={<SuccessfulPayment />}
                    />
                    <Route path="steps" element={<Steps />} />
                    <Route path="/dashboard/sync" element={<Guide />} />
                </Routes>
                <Toaster />
            </IntercomProvider>
        </>
    );
}

export default App;
