import AccountManager from "../sections/AccountManager";
import TopCards from "../sections/TopCards";
import Graph from "../sections/Graph";
import CheckoutHistory from "../sections/CheckoutHistory";
import { useIntercom } from "react-use-intercom";
import { useDataContext } from "../../context/UserContext";
import { plans } from "../../data/config";
import useAxios from "axios-hooks";
import * as Sentry from "@sentry/react";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useEffect } from "react";
import {
    checkForFailedPayment,
    checkForPausedSubscription,
} from "../../utils/dashboard";

export default function Dashboard() {
    const { update } = useIntercom();
    const { user } = useDataContext();

    useEffect(() => {
        checkForFailedPayment(user);
        checkForPausedSubscription(user);
    }, []);

    const [{ data: checkoutList = [] }] = useAxios("/analytics/checkouts");

    let subscriptionName;
    try {
        subscriptionName = plans.find(
            (plan) => plan.priceId === user.subscription.price_id
        ).name;
    } catch (error) {
        subscriptionName = "None";
    }

    update({
        email: user.username,
        userId: user._id.toString(),
        customAttributes: {
            "First Name": user.firstName,
            "Last Name": user.lastName,
            "Created At": user.createdAt.toString(),
            Subscription: subscriptionName,
            "Is Cancelling": user.subscription.deleted,
            "Last Checkout": checkoutList[0]?.time.toString() || "None",
            Deals: checkoutList.filter((checkout) => checkout.newPrice !== 0)
                .length,
            Freebies: checkoutList.filter((checkout) => checkout.newPrice === 0)
                .length,
            "Total Checkouts": checkoutList.length,
        },
    });

    try {
        Sentry.setUser({ email: user.username, id: user._id.toString() });
    } catch (e) {
        console.log(e);
    }

    return (
        <div className="flex flex-col gap-5 bg-zinc-50 p-5 xl:h-[calc(100vh-88px)] xl:flex-row xl:overflow-hidden">
            <div className="order-2 w-full xl:order-1 xl:w-1/4">
                <PerfectScrollbar>
                    <AccountManager />
                </PerfectScrollbar>
            </div>

            <div className="order-1 w-full xl:order-2 xl:w-2/4">
                <div className="mb-2.5 font-semibold">Dashboard</div>
                <TopCards />
                <Graph />
            </div>

            <div className="order-3 w-full xl:order-3 xl:w-1/4">
                <PerfectScrollbar>
                    <CheckoutHistory />
                </PerfectScrollbar>
            </div>
        </div>
    );
}
