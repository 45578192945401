import axios from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useIntercom } from "react-use-intercom";
import {
    FaArrowDown,
    FaArrowUp,
    FaChevronDown,
    FaChevronUp,
    FaExternalLinkAlt,
    FaInfoCircle,
    FaTimes,
} from "react-icons/fa";
import { getActualDiscount, displayDiscount } from "../../utils/discount";

export default function EditModal({
    activeAccount,
    setActiveAccount,
    editAccountModal,
    setEditAccountModal,
    onUpdate,
}) {
    const [discount, setDiscount] = useState(100);
    const [maxPrice, setMaxPrice] = useState(100);
    const [minPrice, setMinPrice] = useState(0);
    const [password, setPassword] = useState("");
    const [blackList, setBlackList] = useState([]);
    const [twoFactorKey, settwoFactorKey] = useState("");
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

    const { showArticle } = useIntercom();

    const updateAccount = async (e) => {
        e.preventDefault();
        if (minPrice > maxPrice) {
            toast.error("Min price can't be more than max price.");
            return;
        }
        if (blackList.length > 0) {
            if (blackList[0] === "") setBlackList([]);
        }

        axios("/accounts", {
            method: "PATCH",
            data: {
                _id: activeAccount._id,
                password,
                twoFactorKey,
                controls: {
                    discount,
                    minPrice,
                    maxPrice,
                    blackList,
                },
            },
        })
            .then(() => {
                toast.success("Account updated!");
                let tempActiveAccount = { ...activeAccount };
                // Updated controls
                tempActiveAccount.controls = {
                    discount,
                    minPrice,
                    maxPrice,
                    blackList,
                };
                setActiveAccount(tempActiveAccount);
                onUpdate();
            })
            .catch((e) => {
                const error = e?.response?.data?.error;
                toast.error(error ?? "Something went wrong!");
            })
            .finally(() => {
                setEditAccountModal(false);
            });
    };

    useEffect(() => {
        setDiscount(activeAccount?.controls?.discount);
        setMaxPrice(activeAccount?.controls?.maxPrice);
        setMinPrice(activeAccount?.controls?.minPrice);
        setBlackList(activeAccount?.controls?.blackList || []);
    }, [
        activeAccount?.controls?.discount,
        activeAccount?.controls?.maxPrice,
        activeAccount?.controls?.minPrice,
        activeAccount?.controls?.blackList,
    ]);

    useEffect(() => {
        if (activeAccount?.isDealAccount && discount === 100) {
            setDiscount(99);
        }
    }, [
        activeAccount?.isDealAccount,
        discount,
        setEditAccountModal,
        editAccountModal,
    ]);

    return (
        <Transition.Root show={Boolean(editAccountModal)} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setEditAccountModal(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded-md bg-white p-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                                <button
                                    onClick={() => setEditAccountModal(false)}
                                    className="absolute top-[23px] right-5 opacity-25"
                                >
                                    <FaTimes />
                                </button>
                                <div className="mb-5 w-full">
                                    <h3 className="text-center leading-none">
                                        Edit account settings
                                    </h3>
                                    <p className="mt-1.5 text-center text-xs">
                                        You are editing the settings for{" "}
                                        <span className="font-medium">
                                            {activeAccount?.email}
                                        </span>
                                    </p>
                                </div>
                                <div className="grid gap-2.5">
                                    <div className="flex items-center gap-2.5 rounded-md border p-5 transition">
                                        <div className="w-full">
                                            <div className="mb-2.5 flex w-full items-center justify-between">
                                                <div className="flex items-center gap-1.5 text-sm text-gray-800">
                                                    Discount Percentage{" "}
                                                    <span
                                                        data-tip="This is the minimum discount percentage you'd like the bot to checkout."
                                                        className="tooltip"
                                                    >
                                                        <FaInfoCircle className="cursor-pointer text-xs opacity-50" />
                                                    </span>
                                                </div>
                                                <span className="text-sm text-gray-800">
                                                    {displayDiscount(discount)}%
                                                </span>
                                            </div>
                                            <input
                                                className="w-full cursor-pointer accent-highlight outline-0"
                                                type="range"
                                                name=""
                                                value={displayDiscount(
                                                    discount
                                                )}
                                                min="70"
                                                max={
                                                    activeAccount?.isDealAccount
                                                        ? "99"
                                                        : "100"
                                                }
                                                onInput={(e) => {
                                                    setDiscount(
                                                        getActualDiscount(
                                                            e.target.value
                                                        )
                                                    );
                                                }}
                                            />
                                            {activeAccount?.isDealAccount ? (
                                                <div className="mt-1.5 text-center text-xs text-gray-500">
                                                    This is a deal account, so
                                                    you cannot choose 100%
                                                    discount!
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2.5 rounded-md border p-5 transition">
                                        <div className="w-full">
                                            <div className="mb-2.5 flex w-full items-center justify-between">
                                                <div className="flex items-center gap-1.5 text-sm text-gray-800">
                                                    Max Price{" "}
                                                    <span
                                                        data-tip="This is the most you will spend on a discounted item."
                                                        className="tooltip"
                                                    >
                                                        <FaInfoCircle className="text-xs opacity-50" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="relative flex items-center">
                                                <div className="absolute right-2.5 z-10 flex flex-col justify-between gap-0.5 text-[0.7rem]">
                                                    <button
                                                        onClick={() => {
                                                            setMaxPrice(
                                                                (prev) =>
                                                                    (
                                                                        Number(
                                                                            prev
                                                                        ) + 0.1
                                                                    ).toFixed(2)
                                                            );
                                                        }}
                                                        className="opacity-50 transition hover:opacity-100"
                                                    >
                                                        <FaChevronUp />
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setMaxPrice(
                                                                (prev) =>
                                                                    (
                                                                        Number(
                                                                            prev
                                                                        ) - 0.1
                                                                    ).toFixed(2)
                                                            );
                                                        }}
                                                        className="opacity-50 transition hover:opacity-100"
                                                    >
                                                        <FaChevronDown />
                                                    </button>
                                                </div>
                                                <div className="absolute left-2.5 z-10 flex flex-col justify-between gap-0.5 text-[0.9rem] opacity-50">
                                                    $
                                                </div>
                                                <input
                                                    maxLength="4"
                                                    type="number"
                                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 pl-5 pr-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                                                    name=""
                                                    value={maxPrice}
                                                    min="0"
                                                    max="100"
                                                    onInput={(e) => {
                                                        setMaxPrice(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2.5 rounded-md border p-5 transition">
                                        <div className="w-full">
                                            <div className="mb-2.5 flex w-full items-center justify-between">
                                                <div className="flex items-center gap-1.5 text-sm text-gray-800">
                                                    Min Price{" "}
                                                    <span
                                                        data-tip="This is the minimum price of the item before discount the bot will search for."
                                                        className="tooltip"
                                                    >
                                                        <FaInfoCircle className="text-xs opacity-50" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="relative flex items-center">
                                                <div className="absolute right-2.5 z-10 flex flex-col justify-between gap-0.5 text-[0.7rem]">
                                                    <button
                                                        onClick={() => {
                                                            setMinPrice(
                                                                (prev) =>
                                                                    (
                                                                        Number(
                                                                            prev
                                                                        ) + 0.1
                                                                    ).toFixed(2)
                                                            );
                                                        }}
                                                        className="opacity-50 transition hover:opacity-100"
                                                    >
                                                        <FaChevronUp />
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setMinPrice(
                                                                (prev) =>
                                                                    (
                                                                        Number(
                                                                            prev
                                                                        ) - 0.1
                                                                    ).toFixed(2)
                                                            );
                                                        }}
                                                        className="opacity-50 transition hover:opacity-100"
                                                    >
                                                        <FaChevronDown />
                                                    </button>
                                                </div>
                                                <div className="absolute left-2.5 z-10 flex flex-col justify-between gap-0.5 text-[0.9rem] opacity-50">
                                                    $
                                                </div>
                                                <input
                                                    maxLength="4"
                                                    type="number"
                                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 pl-5 pr-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                                                    name=""
                                                    value={minPrice}
                                                    min="0"
                                                    max="100"
                                                    onInput={(e) => {
                                                        setMinPrice(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="flex w-full items-center gap-2.5 p-5 text-xs transition"
                                    onClick={() => {
                                        showAdvancedSettings
                                            ? setShowAdvancedSettings(false)
                                            : setShowAdvancedSettings(true);
                                    }}
                                >
                                    <div className="flex items-center gap-1.5 text-sm text-gray-800">
                                        Advanced settings
                                        <span
                                            data-tip="This is the advanced settings."
                                            className="tooltip"
                                        >
                                            {showAdvancedSettings ? (
                                                <FaArrowUp className="text-xs opacity-50" />
                                            ) : (
                                                <FaArrowDown className="text-xs opacity-50" />
                                            )}
                                        </span>
                                    </div>
                                </button>
                                {showAdvancedSettings ? (
                                    <div className="grid gap-2.5">
                                        <div className="flex w-full items-center gap-2.5 rounded-md border p-5 transition">
                                            <div className="w-full">
                                                <div className="mb-2.5 flex w-full items-center justify-between">
                                                    <div className="flex items-center gap-1.5 text-sm text-gray-800">
                                                        Password{" "}
                                                        <span
                                                            data-tip="This is the account password."
                                                            className="tooltip"
                                                        >
                                                            <FaInfoCircle className="text-xs opacity-50" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="relative flex items-center">
                                                    <input
                                                        type="password"
                                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 pl-5 pr-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                                                        name=""
                                                        onInput={(e) => {
                                                            setPassword(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex w-full items-center gap-2.5 rounded-md border p-5 transition">
                                            <div className="w-full">
                                                <div className="mb-2.5 flex w-full items-center justify-between">
                                                    <div className="flex items-center gap-1.5 text-sm text-gray-800">
                                                        2FA Secret{" "}
                                                        <span
                                                            data-tip="This is the account 2FA secret."
                                                            className="tooltip"
                                                        >
                                                            <FaInfoCircle className="text-xs opacity-50" />
                                                        </span>
                                                    </div>
                                                    <button
                                                        className="text-gradient flex items-center gap-1.5 text-xs"
                                                        onClick={() => {
                                                            showArticle(
                                                                7051141
                                                            );
                                                        }}
                                                    >
                                                        Instructions
                                                        <FaExternalLinkAlt className="text-[0.65rem] text-violet-500" />
                                                    </button>
                                                </div>
                                                <div className="relative flex items-center">
                                                    <input
                                                        type="text"
                                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 pl-5 pr-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                                                        name=""
                                                        onInput={(e) => {
                                                            settwoFactorKey(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid items-center gap-2.5 rounded-md border p-5 transition">
                                            <div className="w-full">
                                                <div className="mb-2.5 flex w-full items-center justify-between">
                                                    <div className="flex items-center gap-1.5 text-sm text-gray-800">
                                                        Blacklist{" "}
                                                        <span
                                                            data-tip="This is your filter list."
                                                            className="tooltip"
                                                        >
                                                            <FaInfoCircle className="text-xs opacity-50" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="relative flex items-center">
                                                    <div className="absolute right-2.5 z-10 flex flex-col justify-between gap-0.5 text-[0.7rem]"></div>
                                                    <input
                                                        maxLength="500"
                                                        type="text"
                                                        placeholder="Comma sperated blacklist filter list..."
                                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 pr-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                                                        name=""
                                                        value={
                                                            blackList.length > 0
                                                                ? blackList.join(
                                                                      ", "
                                                                  )
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.nativeEvent
                                                                    .inputType ===
                                                                "deleteContentBackward"
                                                            ) {
                                                                setBlackList(
                                                                    e.target.value
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .map(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item.trim()
                                                                        )
                                                                        .filter(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item !==
                                                                                ""
                                                                        )
                                                                );
                                                            } else {
                                                                setBlackList(
                                                                    e.target.value
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        .map(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item.trim()
                                                                        )
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="button-gradient button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-button-text transition-all lg:text-sm"
                                        onClick={updateAccount}
                                    >
                                        Save changes
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
