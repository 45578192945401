import { FaBars, FaChevronRight, FaTimes } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import clsx from "clsx";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navItems = [
    "Features",
    "How It Works",
    "Latest Deals",
    "Testimonials",
    "FAQ",
    "Pricing",
  ];
  const scrollTo = (id) => {
    const section = document.getElementById(id);
    const yOffset = -112;
    const y =
      section.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <nav>
      <div className="fixed top-0 z-[500] flex w-full flex-col">
        <div>
          <button
            onClick={() => {
              navigate("dashboard/plans");
            }}
            className="bg-gradient flex w-full items-center justify-center bg-black p-2.5 text-sm text-white transition-all hover:shadow-md hover:-hue-rotate-15"
          >
            <p className="hidden text-center lg:block">
              Welcome to FreebieFlow! Use code
              <span className="mx-1 font-semibold tracking-wide">
                SUMMERFUN
              </span>{" "}
              at checkout for $10 off your first month subscription.
            </p>
            <p className="text-center lg:hidden">
              Use code
              <span className="mx-1 font-semibold tracking-wide">
                SUMMERFUN
              </span>
              for $10 off!
            </p>
          </button>
        </div>
        {/* Navbar Div */}
        <div className="flex h-[72px] w-full items-center bg-white/[85%] p-2.5 text-sm shadow backdrop-blur-md transition">
          <div className="relative mx-auto flex w-full max-w-screen-xl items-center justify-between gap-2.5">
            <button
              aria-label="logo"
              onClick={() => {
                location.pathname === "/" ? scrollTo("hero") : navigate("/");
                scrollTo("hero");
              }}
              className="flex items-center gap-1.5 text-xl font-medium lg:w-1/3"
            >
              <img src={logo} alt="" className="h-[50px]" />
            </button>
            <div className="items-center justify-center hidden gap-5 shrink-0 lg:flex">
              {navItems.map((item) => {
                return (
                  <button
                    key={item}
                    onClick={() => {
                      scrollTo(item.replaceAll(" ", "-").toLowerCase());
                    }}
                    className="font-medium tracking-wide transition hover:text-highlight focus:text-highlight"
                  >
                    {item}
                  </button>
                );
              })}
            </div>
            <div className="flex items-center justify-end gap-2.5 lg:w-1/3 lg:gap-5">
              <Link
                to="/dashboard/signin"
                className="hidden font-medium tracking-wide transition hover:opacity-50 lg:flex"
              >
                Sign in
              </Link>
              <Link
                to="/dashboard/register"
                className="button-gradient !hidden lg:!inline-flex lg:text-sm"
              >
                Get Started <FaChevronRight className="text-xs" />
              </Link>
              <button
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
                aria-label="menu"
                className="relative inline-flex items-center gap-1.5 p-5 text-lg font-medium leading-none tracking-wide transition-all active:bg-gray-100 lg:hidden"
              >
                <FaBars
                  className={clsx(
                    "absolute right-2.5 transition duration-500",
                    menuOpen ? "rotate-180 opacity-0" : "rotate-0"
                  )}
                />{" "}
                <FaTimes
                  className={clsx(
                    "absolute right-2.5 transition duration-500",
                    !menuOpen ? "-rotate-180 opacity-0" : "rotate-0"
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[112px]"></div>
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ top: -250 }}
            animate={{ top: 112 }}
            exit={{ top: -250 }}
            transition={{ stiffness: 0, duration: 0.5 }}
            className="fixed z-50 flex flex-col items-end w-full mr-auto overflow-hidden bg-white border-b lg:hidden"
          >
            <div className="flex flex-col items-end gap-5 p-5">
              {navItems.map((item, index) => {
                return (
                  <button
                    key={item}
                    onClick={() => {
                      setMenuOpen(!menuOpen);
                      scrollTo(item.replaceAll(" ", "-").toLowerCase());
                    }}
                    data-aos="fade-down"
                    data-aos-delay={index * 50}
                    className="font-medium tracking-wide transition hover:text-gradient"
                  >
                    {item}
                  </button>
                );
              })}
            </div>
            <div
              data-aos="fade-down"
              data-aos-delay={400}
              className="flex w-full flex-row-reverse justify-start gap-2.5 p-5 pt-0"
            >
              <Link
                to="/dashboard/register"
                className="text-gradient inline-flex items-center gap-1.5 font-semibold"
              >
                Get Started
              </Link>
              or
              <Link
                to="/dashboard/signin"
                className="text-gradient inline-flex items-center gap-1.5 font-semibold"
              >
                Sign in
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Nav;
