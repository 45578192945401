import { CheckIcon } from "@heroicons/react/20/solid";
import Marquee from "react-fast-marquee";
import { FaStar } from "react-icons/fa";
import reviewData from "../../context/reviewData";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useEffect, useState } from "react";
import {
    defaultPriceId,
    stripePublishableKey,
    defaultPrice,
} from "../../config";
import { createSubscription, displayPrice } from "../../utils/checkout";

const stripePromise = loadStripe(stripePublishableKey);

const includedFeatures = [
    "Free Products Weekly",
    "Unlimited Customer Support",
    "Amazon Prime Integration",
    "Access to Warehouse Deals",
    "24/7 Support",
    "Money-back Guarantee",
];

export default function Stripe() {
    const [clientSecret, setClientSecret] = useState("");
    const [elementsInstance, setElementsInstance] = useState(null);

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
    const [showCoupon, setShowCoupon] = useState(false);
    const [couponMessage, setCouponMessage] = useState("");
    const [couponApplied, setCouponApplied] = useState(false);
    const [price, setPrice] = useState(defaultPrice);
    const [coupon, setCoupon] = useState("");

    useEffect(() => {
        createSubscription(defaultPriceId).then((data) => {
            setClientSecret(data.clientSecret);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!clientSecret) return;
        let options = {
            clientSecret,
            appearance: {
                theme: "stripe",
            },
            loader: "always",
        };
        console.log("RELOADING PAYMENT FORM");
        setElementsInstance(
            <Elements
                options={options}
                stripe={stripePromise}
                key={clientSecret}
            >
                <CheckoutForm
                    setClientSecret={setClientSecret}
                    message={message}
                    setMessage={setMessage}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isApplyingCoupon={isApplyingCoupon}
                    setIsApplyingCoupon={setIsApplyingCoupon}
                    showCoupon={showCoupon}
                    setShowCoupon={setShowCoupon}
                    couponMessage={couponMessage}
                    setCouponMessage={setCouponMessage}
                    couponApplied={couponApplied}
                    setCouponApplied={setCouponApplied}
                    price={price}
                    setPrice={setPrice}
                    coupon={coupon}
                    setCoupon={setCoupon}
                />
            </Elements>
        );
    }, [clientSecret]);

    return (
        <div className="min-h-screen overflow-hidden">
            <div className="mx-auto mt-10 flex max-w-screen-lg flex-col lg:flex-row">
                <div className="relative p-8 sm:p-10 lg:flex-auto">
                    <h3 className="inline-block text-4xl font-light tracking-tight">
                        Subscribe to{" "}
                        <span className="text-gradient font-semibold">
                            FreebieFlow
                        </span>{" "}
                    </h3>
                    <h2 className="mt-2.5">
                        {displayPrice(defaultPrice)}
                        <span className="relative bottom-1 left-1 text-lg font-medium">
                            /month
                        </span>
                    </h2>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                        Our easy-to-use AI software scans millions of products
                        for freebies and massive discounts, delivering them to
                        your door with free shipping.
                    </p>
                    <div className="mt-10 flex items-center gap-x-4">
                        <h4 className="flex-none text-sm font-semibold leading-6 text-highlight">
                            What’s included
                        </h4>
                        <div className="h-px flex-auto bg-gray-100" />
                    </div>
                    <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                        {includedFeatures.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                                <CheckIcon
                                    className="h-6 w-5 flex-none text-highlight"
                                    aria-hidden="true"
                                />
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                    <div className="rounded-2xl border bg-white p-10 text-center shadow-sm lg:flex lg:flex-col lg:justify-center">
                        {clientSecret && elementsInstance}
                    </div>
                </div>
            </div>
            <div className="bottom-0 left-0 mx-auto mt-10 mb-10 flex w-full max-w-screen-xl flex-col justify-center gap-5">
                <Marquee speed={45} className="z-90 h-full rounded-md">
                    {reviewData
                        .map((review, index) => {
                            return (
                                <article
                                    key={index}
                                    className="my-3 mr-5 w-60 rounded-md bg-white p-5 shadow-md lg:w-80"
                                >
                                    <div className="w-full">
                                        <div className="mb-1.5 flex flex-col justify-between lg:flex-row lg:items-center ">
                                            <h4 className="text-lg font-semibold">
                                                {review.name}
                                            </h4>
                                            <div className="mb-2.5 mt-1 flex items-center justify-start gap-0.5 text-lg text-[#00B67A] lg:justify-center">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                            </div>
                                        </div>
                                        <p className="line-clamp-2">
                                            {review.body}
                                        </p>
                                    </div>
                                </article>
                            );
                        })
                        .reverse()}
                </Marquee>
            </div>
            <Blobs />
        </div>
    );
}

const Blobs = () => {
    return (
        <>
            <div className="z-back absolute top-52 right-32 aspect-video w-[1500px] rotate-45 blur-3xl lg:top-52 lg:w-[1100px]">
                <svg viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill="#fce7f3"
                        d="M38,-30.7C49.1,-16.4,57.8,-0.6,54.8,12.5C51.8,25.6,37.1,36.1,19.2,47.8C1.3,59.4,-19.9,72.3,-36.2,67.4C-52.5,62.5,-63.9,39.8,-65.3,19.1C-66.8,-1.7,-58.2,-20.6,-45.6,-35.2C-33,-49.8,-16.5,-60,-1.5,-58.8C13.5,-57.6,26.9,-44.9,38,-30.7Z"
                        transform="translate(100 100)"
                    />
                </svg>
            </div>
        </>
    );
};
