import clsx from "clsx";
import { motion } from "framer-motion";

import {
  FaChevronLeft,
  FaChevronRight,
  FaExclamationCircle,
} from "react-icons/fa";

const Card = ({
  index,
  title,
  video,
  description,
  list,
  device,
  manual,
  currentStep,
  goForward,
  goBackward,
  first,
  last,
  setManualSetup,
}) => {
  return (
    <div className="!text-neutral-800 prose w-full max-w-xl overflow-hidden rounded-lg bg-white p-5 shadow-md lg:rounded-3xl lg:p-10">
      <motion.div
        key={index}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        // disable spring
        transition={{ type: "tween" }}
        className="prose"
      >
        <h2>{title}</h2>
        {video ? (
          <div
            className={clsx(
              `${
                (device === "mobile" ? "aspect-square" : null,
                device === "desktop" ? "aspect-video" : null)
              } w-full rounded-3xl border`
            )}
          >
            <img
              src={video || ""}
              alt="Instructional GIF"
              className="object-cover w-full h-full"
            />
          </div>
        ) : null}
        {!video && !first && !last ? (
          <div className="w-full bg-gray-200 border aspect-square rounded-2xl lg:aspect-video"></div>
        ) : null}
        {description ? (
          <div className="mt-5 flex items-center gap-2.5">
            <div>{description}</div>
          </div>
        ) : null}
        {list ? (
          <ol>
            {list.slice(1).map((item, index) => (
              <li key={index + 1}>{item}</li>
            ))}
          </ol>
        ) : null}
        <div
          className={clsx("flex justify-between gap-2.5", last ? null : "mt-5")}
        >
          {!first && !last ? (
            <div className="flex w-full justify-between gap-2.5">
              <button
                onClick={goBackward}
                className="flex h-[36px] w-[36px] shrink-0 items-center justify-center gap-1.5 rounded-full bg-gray-100 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
              >
                <FaChevronLeft />
              </button>
              <button
                onClick={goForward}
                className="flex items-center gap-1.5 rounded-full bg-gray-100 px-5 py-2 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
              >
                Next <FaChevronRight className="text-xs" />
              </button>
            </div>
          ) : null}

          {first ? (
            <div className="flex w-full justify-between gap-2.5">
              <button
                onClick={() => setManualSetup(true)}
                className="flex items-center gap-1.5 rounded-full px-5 py-2 text-sm font-medium !text-gray-500  transition hover:bg-gray-100 hover:!text-gray-800 active:bg-gray-200"
              >
                <FaExclamationCircle /> I know my key
              </button>
              <button
                onClick={goForward}
                className="gradient flex items-center gap-1.5 rounded-full bg-gray-100 px-5 py-2 text-sm font-medium !text-white transition hover:-hue-rotate-15 active:-hue-rotate-30"
              >
                Get my key <FaChevronRight className="text-xs" />
              </button>
            </div>
          ) : null}
        </div>
      </motion.div>
    </div>
  );
};

export default Card;
