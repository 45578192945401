import axios from "axios";
import { churnkeyMode } from "../config";
import toast from "react-hot-toast";

export const checkForPausedSubscription = (user) => {
    return axios("/churnkey", {
        method: "GET",
    })
        .then(({ data }) => {
            window.churnkey.check("pause", {
                subscriptionId: user.subscription.id, // optional
                customerId: user.subscription.customer, // required
                authHash: data.hash, // required
                appId: "2w7hbd3pr", // required
                mode: churnkeyMode, // set to 'test' to hit test billing provider environment
                provider: "stripe", // set to 'stripe', 'chargebee'
                softWall: false, // set to true to allow exiting wall
                forceCheck: false, // recommended to leave this to false to avoid redundant checks
                onError(error, type) {
                    toast.error(error ?? "Something went wrong!");
                },
            });
        })
        .catch((e) => {
            console.log(e);
            const error = e?.response?.data?.error;
            toast.error(error ?? "Something went wrong!");
        });
};

export const checkForFailedPayment = (user) => {
    return axios("/churnkey", {
        method: "GET",
    })
        .then(({ data }) => {
            window.churnkey.check("failed-payment", {
                subscriptionId: user.subscription.id, // optional
                customerId: user.subscription.customer, // required
                authHash: data.hash, // required
                appId: "2w7hbd3pr", // required
                mode: churnkeyMode, // set to 'test' to hit test billing provider environment
                provider: "stripe",
                softWall: false, // set to true to allow exiting wall
                forceCheck: false, // recommended to leave this to false to avoid redundant checks
                gracePeriodDays: 0, // allow a grace period in which failed payment wall is shown but can be exited (soft wall)
                ignoreInvoicesWithoutAttempt: false, // set to true to ignore invoices without a failed charge
                onError(error, type) {
                    toast.error(error ?? "Something went wrong!");
                },
            });
        })
        .catch((e) => {
            console.log(e);
            const error = e?.response?.data?.error;
            toast.error(error ?? "Something went wrong!");
        });
};
