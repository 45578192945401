export const churnkeyMode = process.env.REACT_APP_CHURNKEY_MODE || "live";
export const isTestingEnvironment =
    process.env.REACT_APP_TESTING_ENVIRONMENT === "true" || false;
export const apiUrl =
    process.env.REACT_APP_API_ENDPOINT || "https://api.freebieflow.com";
export const defaultPriceId =
    process.env.REACT_APP_DEFAULT_PRICE_ID || "price_1NELSpLVMiFmGBXg7xkIg6y9";
export const stripePublishableKey =
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    "pk_live_51MWobYLVMiFmGBXgP9w4HKOk35j7cDD15wiaWzIkSzoItZrVid2tzycWkOyr7VBv3VLYBKSQx2sVVAVNorRoQAfU00ZFgCQ6ke";
export const defaultPrice = 39.0;
